const CreateEventState = {
  data: {},
  pages: [
    {
      direction: 'col',
      elements: [
        {
          type: 'file',
          name: 'image',
          placeholder: 'Upload a Poster',
          defaultValue: '',
          label: 'Event Poster'
        }
      ]
    },
    {
      direction: 'col',
      elements: [
        {
          isRequired: true,
          name: 'name',
          placeholder: 'Whats the name of your event',
          defaultValue: '',
          label: 'Event Name',
          full: true
        },
        {
          isRequired: true,
          name: 'location',
          placeholder: 'Where is the event located',
          defaultValue: '',
          label: 'Event Location',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          isRequired: true,
          name: 'startsOn',
          placeholder: 'When does the event start',
          defaultValue: '',
          label: 'Starts On',
          type: 'date',
          full: true
        },
        {
          isRequired: true,
          name: 'startsAt',
          placeholder: 'At what time',
          defaultValue: '',
          label: 'Starts At',
          type: 'time',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          isRequired: true,
          name: 'endsOn',
          placeholder: 'When does the event end',
          defaultValue: '',
          label: 'Ends On',
          type: 'date',
          full: true
        },
        {
          isRequired: true,
          name: 'endsAt',
          placeholder: 'At what time',
          defaultValue: '',
          label: 'Ends At',
          type: 'time',
          full: true
        }
      ]
    },
    {
      direction: 'row',
      elements: [
        {
          isRequired: true,
          name: 'category',
          placeholder: 'What type of event is this ...',
          defaultValue: '',
          label: 'Event Category',
          type: 'select',
          options: [
            { name: 'concert', label: 'Concert' },
            { name: 'party', label: 'Party' },
            { name: 'conference', label: 'Conference' },
            { name: 'webinar', label: 'Webinar' },
            { name: 'trade_show', label: 'Trade Show' },
            { name: 'festival', label: 'Festival' },
            ...[
              {
                id: 22,
                name: 'Auto, Boat & Air'
              },
              {
                id: 23,
                name: 'Seasonal & Holiday'
              },
              {
                id: 24,
                name: 'Science & Technology'
              },
              {
                id: 25,
                name: 'School Activities'
              },
              {
                id: 26,
                name: 'Religion & Spirituality'
              },
              {
                id: 27,
                name: 'Performing & Visual Arts'
              },
              {
                id: 28,
                name: 'Other'
              },
              {
                id: 29,
                name: 'Music'
              },
              {
                id: 30,
                name: 'Home & Lifestyle'
              },
              {
                id: 31,
                name: 'Sports & Fitness'
              },
              {
                id: 32,
                name: 'Hobbies & Special Interest'
              },
              {
                id: 33,
                name: 'Government & Politics'
              },
              {
                id: 34,
                name: 'Food & Drink'
              },
              {
                id: 35,
                name: 'Film, Media & Entertainment'
              },
              {
                id: 36,
                name: 'Fashion & Beauty'
              },
              {
                id: 37,
                name: 'Family & Education'
              },
              {
                id: 38,
                name: 'Community & Culture'
              },
              {
                id: 39,
                name: 'Charity & Causes'
              },
              {
                id: 40,
                name: 'Business & Professional'
              },
              {
                id: 41,
                name: 'Health & Wellness'
              },
              {
                id: 42,
                name: 'Travel & Outdoor'
              }
            ].map(({ name }) => ({ name, label: name }))
          ]
        },
        {
          isRequired: true,
          name: 'organizer',
          placeholder: 'Name of the event organizer',
          defaultValue: '',
          label: 'Event Organizer',
        }
      ]
    },
    {
      name: 'Social Links',
      elements: [
        {
          full: true,
          name: 'facebook',
          placeholder: 'Facebook handle for this event',
          defaultValue: '',
          label: 'Facebook'
        },
        {
          full: true,
          name: 'twitter',
          placeholder: 'Twitter handle for this event',
          defaultValue: '',
          label: 'Twitter'
        },
        {
          full: true,
          name: 'instagram',
          placeholder: 'Instagram handle for this event',
          defaultValue: '',
          label: 'Instagram'
        }
      ]
    },
    {
      direction: 'col',
      elements: [
        {
          name: 'description',
          placeholder: 'Describe your event',
          defaultValue: '',
          label: 'Event Description',
          type: 'textarea',
          full: true
        },
        {
          name: 'tos',
          placeholder: 'Enter any terms of service',
          defaultValue: '',
          label: 'Terms of Service',
          type: 'textarea',
          full: true
        }
      ]
    }
  ]
};

export default CreateEventState;
