import React from 'react';
import fetch from 'cross-fetch';
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createStore, StateMachineProvider } from 'little-state-machine';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { navigate, Script } from 'gatsby';
import { setContext } from '@apollo/link-context';
import { Grommet } from 'grommet/components/Grommet';
import { grommet } from 'grommet/themes/grommet';
import { deepMerge } from 'grommet/utils';
import { habitat } from 'tutadoo-design-system/src/themes';
import CreateEventState from '../store/states/CreateEventState';
import CreateTicketState from '../store/states/CreateTicketState';
import { APIProvider } from '../providers/ApiProvider';
import { isBrowser } from './browser';

const onRedirectCallback = async (appState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true });
};
createStore({
  CreateEventState,
  CreateTicketState
});

const ApolloProviderWithAuth0 = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const httpLink = new HttpLink({
    uri: process.env.GRAPHQL_ENDPOINT || process.env.GATSBY_GRAPHQL_ENDPOINT
  });

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    const claims = await getIdTokenClaims();
    token = claims?.__raw;
    if (!token) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${token}`
      }
    };
  });

  const client = React.useRef();

  if (!client.current) {
    client.current = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export const wrapRootElement = ({ element }) => (
  <>
    <Grommet
      theme={deepMerge(grommet, { ...habitat })}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      full
    >
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN || process.env.GATSBY_AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENTID || process.env.GATSBY_AUTH0_CLIENTID}
        redirectUri={isBrowser && window.location.origin}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <ApolloProviderWithAuth0>
          <APIProvider>
            <StateMachineProvider>{element}</StateMachineProvider>
          </APIProvider>
        </ApolloProviderWithAuth0>
      </Auth0Provider>
    </Grommet>
  </>
);
