const CreateTicketState = {
  data: {},
  pages: [
    {
      direction: 'col',
      elements: [
        {
          isRequired: true,
          name: 'name',
          placeholder: 'Whats the name of your ticket',
          defaultValue: '',
          label: 'Ticket Name',
          full: true
        },
        {
          type: 'radio',
          isRequired: true,
          name: 'type',
          placeholder: 'What is the type of ticket',
          defaultValue: 'free',
          label: 'Type of Ticket',
          full: true,
          options: [
            { name: 'free', label: 'Free', enabled: false },
            { name: 'paid', label: 'Paid', enabled: false },
            { name: 'donation', label: 'Donation', enabled: false }
          ]
        }
      ]
    },
    {
      elements: [
        {
          name: 'quantity',
          placeholder: 'Total number of tickets to be sold',
          defaultValue: '',
          label: 'Total Number Tickets',
          type: 'number',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          isRequired: true,
          name: 'price',
          label: 'Ticket Price',
          defaultValue: '',
          type: 'number',
          watch: {
            field: 'type',
            condition: {
              oneOf: ['paid', 'donation', 'Paid', 'Donation']
            }
          }
        },
        {
          isRequired: true,
          name: 'currency',
          label: 'Ticket Currency',
          defaultValue: '',
          type: 'select',
          options: [
            { name: 'shillings', label: 'KES', enabled: false }
            // { name: 'dollar', label: 'US Dollar', enabled: false }
          ],
          watch: {
            field: 'type',
            condition: {
              oneOf: ['paid', 'donation', 'Paid', 'Donation']
            }
          }
        }
      ]
    },
    // {
    //   elements: [
    //     {
    //       isRequired: true,
    //       name: 'sales_start',
    //       placeholder: 'When does the ticket start',
    //       defaultValue: '',
    //       label: 'Starts On',
    //       type: 'date',
    //       full: true
    //     },
    //     {
    //       isRequired: true,
    //       name: 'sales_start_time',
    //       placeholder: 'At what time',
    //       defaultValue: '',
    //       label: 'Starts At',
    //       type: 'time',
    //       full: true
    //     }
    //   ]
    // },
    // {
    //   elements: [
    //     {
    //       isRequired: true,
    //       name: 'sales_end',
    //       placeholder: 'When does the ticket end',
    //       defaultValue: '',
    //       label: 'Ends On',
    //       type: 'date',
    //       full: true
    //     },
    //     {
    //       isRequired: true,
    //       name: 'sales_end_time',
    //       placeholder: 'At what time',
    //       defaultValue: '',
    //       label: 'Ends At',
    //       type: 'time',
    //       full: true
    //     }
    //   ]
    // },
    {
      elements: [
        {
          name: 'description',
          placeholder: 'Describe your ticket',
          defaultValue: '',
          label: 'Ticket Description',
          type: 'textarea',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          name: 'questions',
          placeholder: '',
          defaultValue: '',
          label: 'Add custom questions for attendees to collect more information,',
          type: 'questions',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          name: 'mode',
          placeholder: '',
          defaultValue: 'create',
          label: 'Form mode',
          type: 'hidden',
          full: true
        }
      ]
    }
  ]
};

export default CreateTicketState;
