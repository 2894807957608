import { useAuth0 } from '@auth0/auth0-react';
import React, {
  useState,
  createContext,
  ReactNode,
  FunctionComponent,
  useEffect,
  useContext
} from 'react';

import { Api } from 'services/api/api';

export interface APIProviderProps {
  children?: ReactNode;
}
export interface APIProviderContextState {
  api: any;
}

const APIContext = createContext({} as APIProviderContextState);

const { Provider, Consumer } = APIContext;

export const APIProvider: FunctionComponent<APIProviderProps> = (props) => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [api, setApi] = useState(new Api());
  const { children } = props;
  useEffect(() => {
    const run = async () => {
      const claims = await getIdTokenClaims();
      const token = claims?.__raw;
      const newAPI = new Api();
      newAPI.setup(token);
      setApi(newAPI);
    };
    run();
  }, [isAuthenticated, getIdTokenClaims]);
  return <Provider value={{ api }}>{children}</Provider>;
};

export const useApi = () => useContext(APIContext);

export default Consumer;
