import env from 'config/env';

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string;
  /**
   * The new URL of the api. This is to help with migration
   */
  mainURL: string;
  /**
   * Hasura secret.
   */
  hasuraSecret: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: env.GRAPHQL_ENDPOINT,
  mainURL: env.GRAPHQL_MAIN_ENDPOINT,
  timeout: 50000
};
